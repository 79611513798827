import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

/**
 * Provides a `safePush` to avoid route pushing errors, like "Error: Abort fetching component for route".
 *
 * @returns safePush: (destination: string | { pathname: string, query: Object }) => void
 */
const useSafeRoutePush = () => {
  const router = useRouter();
  const [changing, setChanging] = useState(false);

  const safePush = useCallback(
    (destination) => {
      if (!router.isReady || changing) return;

      setChanging(true);
      router.push(destination);
    },
    [router, changing]
  );

  useEffect(() => {
    const handleRouteChange = () => setChanging(false);
    const handleRouteChangeStart = () => setChanging(true);

    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('routeChangeStart', handleRouteChangeStart);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, [router, setChanging]);

  return safePush;
};

export default useSafeRoutePush;
