import { isToday, setHours, setMilliseconds, setMinutes, setSeconds } from 'date-fns';

/**
 * Adjusts the given date to represent the end of the day.
 *
 * If the provided date is today, it returns the current date and time.
 * Otherwise, it sets the time to 23:59:59.999 to represent the end of the day.
 *
 * @param {Date} end - The date to be adjusted.
 * @returns {Date} - The adjusted date representing the end of the day.
 */
export default function getEndDateTime(end) {
  let dateToUse = end || new Date();
  const itIsToday = isToday(dateToUse);
  if (itIsToday) {
    dateToUse = new Date();
    dateToUse = setSeconds(dateToUse, 0);
    dateToUse = setMilliseconds(dateToUse, 0);
    return dateToUse;
  }
  end = setHours(dateToUse, 23);
  end = setMinutes(end, 59);
  end = setSeconds(end, 59);
  end = setMilliseconds(end, 999);
  return end;
}
