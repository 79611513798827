// Maybe an ESLint config problem? The stylesheet is exported by the module
// and the real path is /watermelon-reporter/node_modules/@aws-amplify/ui-react/dist/styles.css
// But using real path fails at runtime. Disabling for now.
// eslint-disable-next-line import/no-unresolved
import { useEffect } from 'react';
import useSafeRoutePush from '@/hooks/useSafeRoutePush';
import { Heading, useAuthenticator, withAuthenticator } from '@aws-amplify/ui-react';
import { Amplify, I18n } from 'aws-amplify';
import Head from 'next/head';

import { Filter } from '@cherry/domain';

import '@aws-amplify/ui-react/styles.css';

import useLocalStorage from '@/hooks/useLocalStorage';
import getEndDateTime from '@/lib/getEndDateTime';
import { useRouter } from 'next/router';

import awsConfig from '../aws-exports';

Amplify.configure(awsConfig);

I18n.putVocabularies({ en: { Username: 'Email' } });

const components = {
  Header() {
    return (
      <Heading marginBottom="2rem" level={1} textAlign="center">
        Collect
      </Heading>
    );
  },
};

const AuthenticatorView = () => {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const safePush = useSafeRoutePush();
  const router = useRouter();
  const [selectedTimezone] = useLocalStorage('selectedTimezone');

  useEffect(() => {
    if (authStatus !== 'authenticated') return;

    const pathname = router.query?.redirect ?? '/';

    safePush(
      pathname === '/'
        ? {
            pathname,
            query: {
              ...new Filter({ tz: selectedTimezone }).query,
              endDate: Filter.serializeDate(getEndDateTime()),
              page: 0,
              pageSize: 15,
            },
          }
        : { pathname }
    );
  }, [authStatus, safePush, router, selectedTimezone]);
};

const AuthenticatorContainer = withAuthenticator(AuthenticatorView, {
  hideSignUp: true,
  components,
  variation: 'default',
  formFields: {
    signIn: {
      username: {
        type: 'email',
        isRequired: true,
        autoFocus: true,
      },
    },
  },
});

const Login = () => (
  <>
    <Head>
      <title>Collect | Sign In</title>
    </Head>
    <AuthenticatorContainer />
  </>
);

export default Login;
